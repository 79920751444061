import * as React from "react";
import Layout from "../../../components/Layout";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import ScopriAltriBonusECM from "../../../components/ScopriAltriBonusECM/ScopriAltriBonusECM";

export default function DossierFormativoPage() {
  return (
    <Layout
      pageTitle="Bonus ECM - Dossier Formativo"
      description="Bonus ECM: grazie al dossier formativo puoi ottenere una riduzione fino a 30 crediti ECM  per il trienneio formativo 2020 - 2022"
    >
      <section className="position-relative">
        <StaticImage
          src="../../../images/background-bonus-crediti.png"
          className="img-header-home"
          alt="Bonus ECM"
          title="Bonus ECM"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1
              title="BONUS ECM - Riduzione degli ECM con il Dossier Formativo"
              className="text-center text-white display-3"
            >
              BONUS ECM - Riduzione degli ECM con il Dossier Formativo
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Ottieni il bonus fino a 30 crediti ECM grazie alla compilazione
              del Dossier Formativo, individuale o di gruppo.
              <br /> Puoi ottenere una riduzione di 10 crediti per il triennio
              2020 - 2022 ed i restanti 20 nel triennio 2023-2025 .
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri di più
            </a>
          </div>
        </div>
      </section>
      <div id="sezione-corsi" className="container-fluid p-5">
        <div className="row">
          <div className="col-12">
            <p>
              Il Dossier Formativo costituisce lo{" "}
              <b>
                strumento attraverso il quale il professionista sanitario
                programma e verifica il proprio percorso formativo
              </b>
              . Nella creazione del Dossier Formativo Individuale si dovrà
              prevedere che il singolo professionista ponderi la{" "}
              <b>
                programmazione degli obiettivi da realizzare nell'arco del
                triennio
              </b>
              , monitorandone la progressione anno per anno.
            </p>
            <p>
              Il professionista ha la possibilità di impostare il proprio
              Dossier identificando gli obiettivi tecnico-professionali, di
              processo o di sistema, dimensionando percentualmente gli obiettivi
              nel rispetto del limite massimo di 10 obiettivi. In questo modo si
              avranno a disposizione i 10 obiettivi formativi in cui
              ricomprendere lo sviluppo formativo triennale.
            </p>
            <p>
              Il Dossier Formativo prevede un bonus per il professionista, che
              viene erogato al realizzarsi di tutte le seguenti condizioni:{" "}
            </p>
            <ul>
              <li>
                <b>Costruzione del Dossier</b>;
              </li>
              <li>
                <b>Congruità del Dossier con la professione esercitata</b>;
              </li>
              <li>
                <b>
                  Coerenza relativamente alle aree (pari ad almeno il 70%) tra
                  il Dossier programmato e quello effettivamente realizzato
                </b>
                .
              </li>
            </ul>
            <p>
              Il bonus, quale riduzione dell'obbligo formativo del singolo
              professionista, è quantificato nella misura massima di{" "}
              <b>30 crediti formativi</b>, di cui 10 assegnati già nel triennio
              2020-2022 per la costruzione di un Dossier Formativo. I restanti
              20 crediti di bonus saranno assegnati nel triennio successivo
              rispetto a quello in cui si è costruito il dossier, qualora il
              Dossier sia stato sviluppato nel rispetto dei 3 principi sopra
              indicati.
            </p>
            <p>
              Nel caso in cui il Dossier fosse elaborato e realizzato nel
              secondo anno del triennio si avrà diritto ad un bonus pari a 15
              crediti formativi per il triennio successivo, mentre il bonus sarà
              pari a 10 crediti formativi per il triennio successivo se il
              Dossier fosse elaborato e realizzato nell'ultimo anno del
              triennio.
            </p>
          </div>
        </div>
      </div>
      <div id="sezione-corsi" className="container-fluid p-5">
        <div className="row">
          <div className="col-12 col-md-6 text-center h-100 d-flex flex-column justify-content-between">
            <h2 title="Video Tutorial creazione Dossier formativo">
              Video Tutorial creazione Dossier Formativo
            </h2>
            <p>
              Il Dossier Formativo è espressione della programmazione,
              dell&#180; aggiornamento nel tempo e della coerenza della
              formazione aggiornamento rispetto alla professione, alla
              disciplina, alla specializzazione ed al profilo di competenze
              nell’esercizio professionale quotidiano.
              <br /> Costituisce lo strumento attraverso il quale il
              professionista sanitario programma e verifica il proprio percorso
              formativo alla luce del suo profilo professionale e della propria
              posizione.
            </p>
            <p>
              Risulta pertanto essere uno strumento idoneo a rilevare i bisogni
              formativi dei professionisti sanitari, contribuendo così ad
              indirizzare e qualificare l&#180;offerta foramtiva proposta dai
              Provider.{" "}
            </p>
          </div>
          <div className="col-12 col-md-6 h-100 d-flex flex-column justify-content-between">
            <iframe
              width="100%"
              height="250px"
              src="https://www.youtube.com/embed/h6Za9sk_PMA"
              title="Video Tutorial creazione Dossier formativo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="col-12 col-md-6 mt-5 text-center">
            <h3 title="Guida per la creazione del Dossier formativo individuale">
              Guida per la creazione del Dossier foramtivo individuale
            </h3>
            <p>
              Come stabilito dalla delibera della Commissione Nazionale per la
              Formazione Continua del 4 novembre 2016, i soggetti abilitati alla
              costruzione del Dossier Formativo Individuale sono tutti i
              Professionisti sanitari che, nel rispetto dell’obbligo formativo
              individuale ECM, vogliono programmare il proprio percorso
              formativo e vedersi riconosciuto un bonus, sull’obbligo formativo
              individuale triennale, nel rispetto di quanto stabilito dalla
              normativa vigente.
            </p>
            <a
              href="/Guida_utente_df_individ.pdf"
              rel="noopener"
              target="_blank"
              title="Guida Co.Ge.A.P.S. dossier formativo individuale"
            >
              <p>
                <strong>SCARICA LA GUIDA</strong>
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 mt-5 text-center">
            <h3 title="Guida per la creazione del Dossier formativo di gruppo">
              Guida per la creazione del Dossier formativo di gruppo
            </h3>
            <p>
              Come stabilito dalla delibera della Commissione Nazionale per la
              Formazione Continua del 4 novembre 2016, i soggetti abilitati alla
              costruzione del Dossier Formativo Individuale sono tutti i
              Professionisti sanitari che, nel rispetto dell’obbligo formativo
              individuale ECM, vogliono programmare il proprio percorso
              formativo e vedersi riconosciuto un bonus, sull’obbligo formativo
              individuale triennale, nel rispetto di quanto stabilito dalla
              normativa vigente
            </p>
            <a
              href="/Guida_utente_df_gruppo.pdf"
              rel="noopener noreferrer"
              target="_blank"
              title="Guida Co.Ge.A.P.S. dossier formativo di gruppo"
            >
              <p>
                <strong>SCARICA LA GUIDA</strong>
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h4
              title="Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?"
              className="my-4 text-center titolo-sezione fw-bold"
              style={{ fontSize: 32 }}
            >
              Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?
            </h4>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 text-center">
            <a
              href="http://d7g5i.emailsp.com/frontend/forms/Subscription.aspx?idList=28&idForm=285&guid=5ddc68f0-845c-4ca3-b4ef-d19c93033a5c"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary btn-lg"
            >
              Iscriviti alla newsletter
            </a>

            <p className="text-center">
              I tuoi dati non verranno condivisi con nessun Provider.
            </p>
          </div>
        </div>
      </div>
      <ScopriAltriBonusECM
        emergenzaSanitaria={true}
        formazioneIndividuale={true}
        sperimentazioneMedicinale={true}
      />
    </Layout>
  );
}
