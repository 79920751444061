import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function ScopriAltriBonusECM({
  dossierFormativo,
  emergenzaSanitaria,
  formazioneIndividuale,
  sperimentazioneMedicinale,
}) {
  return (
    <div className="container-fluid pb-5">
      <div className="row">
        <div className="col-12">
          <h3 className="titolo-sezione text-center my-3">
            Scopri gli altri Bonus ECM
          </h3>
        </div>
        {dossierFormativo && (
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-dossier-formativo/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_Online_information_re_erks.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Dossier Formativo"
                  title="Bonus ECM - Dossier Formativo"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">Bonus ECM - Dossier Formativo</h4>
                </div>
              </div>
            </Link>
          </div>
        )}
        {emergenzaSanitaria && (
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-emergenza-sanitaria/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_doctor_kw5l.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Emergenza Sanitaria"
                  title="Bonus ECM - Emergenza Sanitaria"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">
                    Bonus ECM - Emergenza Sanitaria
                  </h4>
                </div>
              </div>
            </Link>
          </div>
        )}
        {formazioneIndividuale && (
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-formazione-individuale/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_Studying_re_deca.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Formazione individuale"
                  title="Bonus ECM - Formazione individuale"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">
                    Bonus ECM - Formazione individuale
                  </h4>
                </div>
              </div>
            </Link>
          </div>
        )}
        {sperimentazioneMedicinale && (
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-sperimentazione-medicinali/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_Scientist_0ft9.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Sperimentazione Medicinali"
                  title="Bonus ECM - Sperimentazione Medicinali"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">
                    Bonus ECM - Sperimentazione Medicinali
                  </h4>
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
